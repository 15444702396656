import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from './pages/Home'
import Contact from './pages/Contact'
import Appointment from './pages/Appointment'
import Events from './pages/Events'
import ThankU from './pages/ThankU'
import Test from './pages/test'
import ENT from './pages/services/ENT'
import Dental from './pages/services/dental'
import Blogs from './pages/blogs/blogs'
import AboutDr from './pages/AboutDr'

import E04022024_01 from './events/E04022024_01';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>   
        <Route path="/about" element={<AboutDr />} /> 
        <Route path="/contact" element={<Contact />} /> 
        <Route path="/test" element={<Test />} />

        {/* contact */}
        <Route path="/contact/thankU" element={<ThankU />} /> 

        {/* services */}
        <Route path="/services/ENT" element={<ENT />} />
        <Route path="/services/dental" element={<Dental />} />

        {/* appointment */}                 
        <Route path="/appointment" element={<Appointment />} />
        <Route path="/appointment/thankU" element={<ThankU />} />  

        {/* blogs */}      
        {/* <Route path="/blogs" element={<Blogs />}/> */}

        {/* events  */}
        <Route path="/events" element={<Events />}/>
        <Route path="/events/E04022024_01" element={<E04022024_01 />}/>        

      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);