import Topbar from '../components/Topbar.jsx'
import SafalyaNavbar from '../components/SafalyaNavbar.jsx'
import Whatsappflot from '../components/Whatsappflot.jsx'
import Footer from '../components/Footer.jsx'
import About from '../components/About.jsx'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function AboutDr() {    
    return (
        <main>
            <Topbar/>
            <SafalyaNavbar/>  
            <Whatsappflot/>         
            <div className="container-fluid bg-primary-dark p-3">                    
                <div style={{alignItems: "center", display: "flex", justifyContent: "space-evenly", flexDirection: "column"}}>
                    <img src={require("../img/jeet_photo.JPG")} style={{borderRadius: "50%", width: "200px", height: "200px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}/>
                    <div className="text-center text-white pt-3">
                        <h3 className="text-white border-5">Dr. Jeet Amin</h3>   
                        <p>
                            MS (ENT) <br/>
                            Endoscopic Skull Base Surgeon<br/>
                            Fellowship in Endoscopic Skull Base Surgeon<br/>
                            (Mumbai)<br/>
                            Fellowship in LateralSkull Base Surgery (Italy)
                        </p>
                    </div>
                </div>                        
            </div>   
            <div className='m-4 p-3 shadow-box'>
            <p> 
                He is a highly skilled ENT and skull base surgeon with extensive training and expertise. 
                He earned his MBBS from the esteemed B.J. Medical College, Ahmedabad, followed by a Master of 
                Surgery in ENT and Head & Neck Surgery from PDU Medical College and Hospital, Rajkot.
            </p>
            <p>
                To further advance his skills, he completed a fellowship in middle ear surgery, auditory implants, 
                and lateral skull base surgery in Milan, Italy. He then specialized in endoscopic transnasal skull 
                base surgery through a fellowship at Nanavati Max Super Speciality Hospital, Mumbai, under the 
                mentorship of renowned Dr. Narayan Jayashankar.
            </p> 
            <p>
                With proficiency in neurotology, lateral skull base surgery, and endoscopic sinus and skull base surgery, 
                he is dedicated to treating patients with conditions like facial palsy, sinus allergies, vertigo, and thyroid disorders. 
                He performs a wide range of surgeries, including those related to the ear, nose, head, neck, and skull base, 
                delivering exceptional care with a patient-centered approach.
            </p> 
            </div>                      
            <div className="text-center mx-auto m-5" style={{maxWidth: "500px"}}>
                <h3 className="d-inline-block color-primary-dark text-uppercase border-5">Attached At</h3>                
            </div> 
            <Row xs={1} md={4} sm={2} style={{margin: "10px"}}>
                <Col >
                    <img src={require("../img/LOGO.png")} style={{margin: "15px", width: "100%", height: "100px",objectFit: "fill"}}/>  
                </Col>    
                <Col >
                    <img src={require("../img/ashka_hospital_logo.png")} style={{margin: "15px", width: "100%", height: "100px",objectFit: "fill"}}/>  
                </Col>  
                <Col >
                    <img src={require("../img/empire_doctor_house_logo.jpg")} style={{margin: "15px", width: "100%", height: "100px",objectFit: "fill"}}/>  
                </Col>  
                <Col >
                    <img src={require("../img/SGS_hospital_logo.png")} style={{margin: "15px", width: "100%", height: "100px",objectFit: "fill"}}/>  
                </Col>          
            </Row> 
            <Footer/>
        </main>
    )
}